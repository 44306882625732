import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "../components/guidedprayer.css"

export default function GuidedPrayer() {
  const data = useStaticQuery(graphql`
    query {
      prayerArtwork: file(relativePath: { eq: "GuidedPrayer.jpg" }) {
        childImageSharp {
          fixed(quality: 80, width: 124) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Guided Prayer" />
      <div className="PageHeaderWImg">
        <Img fixed={data.prayerArtwork.childImageSharp.fixed} />
        <h1>Guided Prayer</h1>
      </div>
      <p className="PrayerCaption">
        We’re recording weekly guided prayers during our Arise and Walk Forth
        Series through the beatitudes of Jesus. We hope they give you special
        moments of prayer!
      </p>

      <div className="PrayerListWrap">
        <a href="https://youtu.be/tfUUYB9xOwo">
          <div className="PrayerContainer">
            <h3>Blessed</h3>
            <p>Week 1</p>
          </div>
        </a>
        <a href="https://youtu.be/Ca6KBgOEXBA">
          <div className="PrayerContainer">
            <h3>Your Greatest Need</h3>
            <p>Week 2</p>
          </div>
        </a>
        <a href="https://youtu.be/qJJYcO90A3Q">
          <div className="PrayerContainer">
            <h3>Comfort</h3>
            <p>Week 3</p>
          </div>
        </a>
        <a href="https://youtu.be/Vz-arhcH-kw">
          <div className="PrayerContainer">
            <h3>Who You Are</h3>
            <p>Week 4</p>
          </div>
        </a>
        <a href="https://youtu.be/26LIVvs3yaw">
          <div className="PrayerContainer">
            <h3>He Is Here For You</h3>
            <p>Week 5</p>
          </div>
        </a>
        <a href="https://youtu.be/NZe0CxSX6S4">
          <div className="PrayerContainer">
            <h3>Fortunate</h3>
            <p>Week 6</p>
          </div>
        </a>
        <a href="https://youtu.be/1wjQAml5gBQ">
          <div className="PrayerContainer">
            <h3>He Knows You</h3>
            <p>Week 7</p>
          </div>
        </a>
      </div>
    </Layout>
  )
}
